/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Script } from 'gatsby';
import classToggler from '@flixbus/honeycomb/dist/js/classToggler'; // making honeycomb static examples work
import dropdown from '@flixbus/honeycomb/dist/js/dropdown'; // making honeycomb static examples work
import tooltip from '@flixbus/honeycomb/dist/js/tooltip'; // making honeycomb static examples work
import popup from '@flixbus/honeycomb/dist/js/popup'; // making honeycomb static examples work
import tabs from '@flixbus/honeycomb/dist/js/tabs'; // making honeycomb static examples work
import ThemeContextProvider from './src/context/themeContext';
import NotificationContextProvider from './src/context/notificationContext';
// using raw loader in order to get CSS string
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import honeycombStaticCss from '!raw-loader!@flixbus/honeycomb/dist/css/honeycomb.min.css';
import './src/scss/common.scss';

export const wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <ThemeContextProvider>
    <NotificationContextProvider>
      {element}
    </NotificationContextProvider>
    {process.env.NODE_ENV === 'production' && (
      <Script id="hc-gtm-include">
        {'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'GTM-WB82QC4\');'}
      </Script>
    )}
  </ThemeContextProvider>
);

export const wrapPageElement = ({ element: pageElement }) => (
  <>
    {pageElement}
    <Script type="module" src="/static/icons/flix-icons/flix-icons.esm.js" />
    <Script type="nomodule" src="/static/icons/flix-icons/flix-icons.js" />
    <Script type="module" src="/static/illustrations/flix-illustrations/flix-illustrations.esm.js" />
    <Script type="nomodule" src="/static/illustrations/flix-illustrations/flix-illustrations.js" />
    <Script type="module" src="/static/honeycomb-flags-web-components/hc-flag/hc-flag.esm.js" />
    <Script type="nomodule" src="/static/honeycomb-flags-web-components/hc-flag/hc-flag.js" />
  </>
);

export const onRouteUpdate = ({ location }) => {
  // injecting icon fonts for icon page if wasn't added with SSR already
  if (location.pathname.match(/\/icons|\/components|\/guides/)) {
    if (!document.querySelector('link[href="/static/icons/fonts/flix-icon.css"]')) {
      const node = document.createElement('link');
      node.setAttribute('rel', 'stylesheet');
      node.setAttribute('href', '/static/icons/fonts/flix-icon.css');
      document.head.append(node);
    }
  }
  // adds static stylesheet on components and hc-examples-doc page
  if (location.pathname.match(/\/components|\/icons|\/flags|\/examples|\/guides/) && !document.getElementById('honeycomb-static-stylesheet')) {
    const node = document.createElement('style');
    node.textContent = honeycombStaticCss;
    node.setAttribute('type', 'text/css');
    node.id = 'honeycomb-static-stylesheet';
    document.head.append(node);
  }

  // initializing plugins for all components and guides making the dynamic examples work
  if (location.pathname.match(/components|guides/)) {
    classToggler.init();
    dropdown.init();
    popup.init();
    tooltip.init();
    tabs.init();
  }
};
