import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext({
  messages: [],
  addMessage: () => {},
  removeMessage: () => {},
});

export default function NotificationContextProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const removeMessage = useCallback((timestamp) => {
    const messageIndexToRemove = messages.findIndex((message) => message.timestamp === timestamp);
    // clearing up messages with a smaller index if any
    messages.splice(0, messageIndexToRemove + 1);
    setMessages([...messages]);
  }, []);
  const addMessage = useCallback((messageText, status) => {
    messages.push({ message: messageText, status, timestamp: Date.now() });
    setMessages([...messages]);
  }, []);

  const contextValue = useMemo(() => ({
    messages,
    addMessage: (messageText, status) => addMessage(messageText, status),
    removeMessage: (timestamp) => removeMessage(timestamp),
  }), [messages, addMessage, removeMessage]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
