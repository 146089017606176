import {
  themeFlix,
  themeFlixDark,
  themeHighContrast,
  themeKamil,
  themeKamilDark,
  themeNeptune,
  themeNeptuneDark,
} from '@flixbus/honeycomb-react';

const themesCollection = [
  {
    name: 'Flix',
    theme: 'flix',
    brand: 'flix',
    mode: 'light',
    tokens: themeFlix,
    default: true,
  }, {
    name: 'Flix Dark',
    theme: 'flix-dark',
    brand: 'flix',
    mode: 'dark',
    tokens: themeFlixDark,
  }, {
    name: 'Kamil',
    theme: 'kamil',
    brand: 'kamil',
    mode: 'light',
    tokens: themeKamil,
  }, {
    name: 'Kamil Dark',
    theme: 'kamil-dark',
    brand: 'kamil',
    mode: 'dark',
    tokens: themeKamilDark,
  }, {
    name: 'Neptune',
    theme: 'neptune',
    brand: 'neptune',
    mode: 'light',
    tokens: themeNeptune,
  }, {
    name: 'Neptune Dark',
    theme: 'neptune-dark',
    brand: 'neptune',
    mode: 'dark',
    tokens: themeNeptuneDark,
  }, {
    name: 'High Contrast',
    theme: 'high-contrast',
    brand: 'high-contrast',
    mode: 'dark',
    tokens: themeHighContrast,
  },
];

export const HONEYCOMB_DEFAULT_THEME = themesCollection.find((theme) => theme.default).theme;

// only list brand themes
export const HONEYCOMB_BRANDS = [...new Set(themesCollection.map((theme) => theme.brand))];

// list theme modes
export const HONEYCOMB_THEME_MODES = [...new Set(themesCollection.map((theme) => theme.mode)), 'device-settings'];

export const themeWrapperObj = themesCollection.reduce((acc, theme) => {
  acc[theme.theme] = theme.tokens;
  return acc;
}, {});

export const themeSelectOptionsArray = themesCollection.map((theme) => ({
  value: theme.theme,
  name: theme.name,
}));

export default themesCollection;
