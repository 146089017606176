exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-assets-flags-jsx": () => import("./../../../src/pages/assets/flags/[...].jsx" /* webpackChunkName: "component---src-pages-assets-flags-jsx" */),
  "component---src-pages-assets-icons-jsx": () => import("./../../../src/pages/assets/icons/[...].jsx" /* webpackChunkName: "component---src-pages-assets-icons-jsx" */),
  "component---src-pages-assets-illustrations-jsx": () => import("./../../../src/pages/assets/illustrations/[...].jsx" /* webpackChunkName: "component---src-pages-assets-illustrations-jsx" */),
  "component---src-pages-assets-index-jsx": () => import("./../../../src/pages/assets/index.jsx" /* webpackChunkName: "component---src-pages-assets-index-jsx" */),
  "component---src-pages-assets-logos-jsx": () => import("./../../../src/pages/assets/logos.jsx" /* webpackChunkName: "component---src-pages-assets-logos-jsx" */),
  "component---src-pages-components-changelog-jsx": () => import("./../../../src/pages/components/changelog.jsx" /* webpackChunkName: "component---src-pages-components-changelog-jsx" */),
  "component---src-pages-components-design-tokens-colors-jsx": () => import("./../../../src/pages/components/design-tokens/colors/[...].jsx" /* webpackChunkName: "component---src-pages-components-design-tokens-colors-jsx" */),
  "component---src-pages-components-design-tokens-effects-jsx": () => import("./../../../src/pages/components/design-tokens/effects/[...].jsx" /* webpackChunkName: "component---src-pages-components-design-tokens-effects-jsx" */),
  "component---src-pages-components-design-tokens-index-jsx": () => import("./../../../src/pages/components/design-tokens/index.jsx" /* webpackChunkName: "component---src-pages-components-design-tokens-index-jsx" */),
  "component---src-pages-components-design-tokens-spacing-jsx": () => import("./../../../src/pages/components/design-tokens/spacing/[...].jsx" /* webpackChunkName: "component---src-pages-components-design-tokens-spacing-jsx" */),
  "component---src-pages-components-design-tokens-typography-jsx": () => import("./../../../src/pages/components/design-tokens/typography/[...].jsx" /* webpackChunkName: "component---src-pages-components-design-tokens-typography-jsx" */),
  "component---src-pages-components-index-jsx": () => import("./../../../src/pages/components/index.jsx" /* webpackChunkName: "component---src-pages-components-index-jsx" */),
  "component---src-pages-credits-index-jsx": () => import("./../../../src/pages/credits/index.jsx" /* webpackChunkName: "component---src-pages-credits-index-jsx" */),
  "component---src-pages-emails-index-jsx": () => import("./../../../src/pages/emails/index.jsx" /* webpackChunkName: "component---src-pages-emails-index-jsx" */),
  "component---src-pages-examples-index-jsx": () => import("./../../../src/pages/examples/index.jsx" /* webpackChunkName: "component---src-pages-examples-index-jsx" */),
  "component---src-pages-guides-index-jsx": () => import("./../../../src/pages/guides/index.jsx" /* webpackChunkName: "component---src-pages-guides-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-new-version-highlights-jsx": () => import("./../../../src/pages/new-version-highlights.jsx" /* webpackChunkName: "component---src-pages-new-version-highlights-jsx" */),
  "component---src-templates-assets-page-template-jsx": () => import("./../../../src/templates/assets-page-template.jsx" /* webpackChunkName: "component---src-templates-assets-page-template-jsx" */),
  "component---src-templates-component-doc-template-jsx": () => import("./../../../src/templates/component-doc-template.jsx" /* webpackChunkName: "component---src-templates-component-doc-template-jsx" */),
  "component---src-templates-components-basics-page-template-jsx": () => import("./../../../src/templates/components-basics-page-template.jsx" /* webpackChunkName: "component---src-templates-components-basics-page-template-jsx" */),
  "component---src-templates-email-template-doc-email-template-doc-jsx": () => import("./../../../src/templates/email-template-doc/email-template-doc.jsx" /* webpackChunkName: "component---src-templates-email-template-doc-email-template-doc-jsx" */),
  "component---src-templates-emails-page-template-jsx": () => import("./../../../src/templates/emails-page-template.jsx" /* webpackChunkName: "component---src-templates-emails-page-template-jsx" */),
  "component---src-templates-guides-page-template-jsx": () => import("./../../../src/templates/guides-page-template.jsx" /* webpackChunkName: "component---src-templates-guides-page-template-jsx" */)
}

