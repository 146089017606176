import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useThemeSwitcher } from '@flixbus/honeycomb-react';
import { HONEYCOMB_DEFAULT_THEME } from '../components/layout/themes-config';

export const HONEYCOMB_DEFAULT_THEME_MODE = 'device-settings'; // uses device settings
// local storage keys
export const STORAGE_KEY_HONEYCOMB_THEME = 'honeycomb-theme';
export const STORAGE_KEY_HONEYCOMB_THEME_MODE = 'honeycomb-theme-mode';

export const ThemeContext = React.createContext({
  theme: HONEYCOMB_DEFAULT_THEME, // brand themes
  mode: HONEYCOMB_DEFAULT_THEME_MODE, // modes
  appTheme: '', // assembled app theme to use in classnames or as a ThemeWrapper prop
  setTheme: () => {}, // sets brand theme to use and stores it
  setMode: () => {}, // sets mode for the stored theme
});

function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(HONEYCOMB_DEFAULT_THEME);
  const [mode, setMode] = useState(HONEYCOMB_DEFAULT_THEME_MODE);
  useEffect(() => {
    function loadTheme() {
      const storedTheme = localStorage.getItem(STORAGE_KEY_HONEYCOMB_THEME);
      return storedTheme || HONEYCOMB_DEFAULT_THEME;
    }
    function loadMode() {
      const storedMode = localStorage.getItem(STORAGE_KEY_HONEYCOMB_THEME_MODE);
      return storedMode || HONEYCOMB_DEFAULT_THEME_MODE;
    }
    setTheme(loadTheme());
    setMode(loadMode());
  }, []);
  // updating storage
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY_HONEYCOMB_THEME, theme);
    localStorage.setItem(STORAGE_KEY_HONEYCOMB_THEME_MODE, mode);
  }, [theme, mode]);
  // updating resulting theme name with useThemeSwitcher hook
  // only run it on clientside
  const [appTheme] = (typeof window !== 'undefined') ? useThemeSwitcher({
    lightTheme: theme,
    darkTheme: (theme !== 'high-contrast') ? `${theme}-dark` : theme,
    switchCondition: (mode === 'device-settings') ? undefined : mode === 'dark',
  }) : [HONEYCOMB_DEFAULT_THEME];
  return (
    <ThemeContext.Provider
      value={
        useMemo(() => (
          {
            theme,
            mode,
            setTheme,
            setMode,
            appTheme,
          }
        ), [theme, mode, setTheme, setMode, appTheme])
      }
    >
      {children}
    </ThemeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContextProvider;
